import { post, get } from '@/utils/request';

// 门店货品清单
const getAllGoodsList = (data) => post({
    url: '/store/goods.Goods/getAllGoodsList',
    data: data
})

// 获取货品清单
const getList = (data) => post({
    url: '/store/goods.Goods/getGoodsList',
    responseType: data.export && data.export === 1 ? 'blob' : null,
    data: data
});

const getAllGoodsListNew = (data) => post({
    url: '/store/goods.Goods/getAllGoodsListNew',
    data: data
});

// 详情title接口
const getDetailTitle = (data) => post({
    url: '/store/goods.Goods/getGoodsDetailsTitle',
    data: data
})

// 详情数据接口
const getDetailsForGoods = (data) => post({
    url: '/store/goods.Goods/getDetailsForGoods',
    data: data
})

// 快照
const getGoodsSnap = (data) => post({
    url: '/store/goods.Goods/getGoodsSnap',
    data: data
})

// 回收快照
const getRecycleGoodsSnap = (data) => post({
    url: '/store/goods.Goods/getRecycleGoodsSnap',
    data: data
})

// 统计数据
const getGoodsCount = (data) => post({
    url: '/store/goods.Goods/getGoodsCount',
    data: data
})


// 搜索货品
const getGoodsSearchReq = (params) => post({
    url: '/store/goods.Goods/getGoodsSearch',
    data: {
        ...params,
    },
});

// 操作日志
const getGoodsLog = (data) => post({
    url: '/store/goods.Goods/getGoodsLog',
    data: data
})

// 货品搜索
const getGoodsSearch = (data) => post({
    url: '/store/goods.Goods/getGoodsSearch',
    data: data
})

// 货品清单打印数据
const getGoodsPrintReq = (data) => post({
    url: '/store/goods.Goods/goodsPrint',
    data: data
})
//解除挂单商品
const unlockMountGoods = (data) => post({
    url:'/store/order.Order/unlockGoodsMount',
    data:data
})
export {
    getGoodsPrintReq,
    getList,
    getDetailTitle,
    getDetailsForGoods,
    getGoodsCount,
    getGoodsSearchReq,
    getGoodsLog,
    getGoodsSnap,
    getRecycleGoodsSnap,
    getGoodsSearch,
    getAllGoodsList,
    getAllGoodsListNew,
    unlockMountGoods
}