<template>
  <div>
    <div class="content" v-show="!visiable">
      <div class="card" style="display: flex">
        <div class="left" style="flex-shrink: 0">
          <el-button
            :loading="exportLoading"
            type="primary"
            @click="handleExport"
            icon="el-icon-upload2"
            >导出</el-button
          >
          <!-- <el-button
            type="primary"
            plain
            @click="handleGoodsAll"
            icon="el-icon-download"
            >批量导入货品图</el-button
          > -->
        </div>
        <div class="line" style="margin: none !important"></div>
        <div class="middle">
          <Cond
            @onCond="handleCond"
            function_code="allGoodsList"
            other_code="allGoodsList"
          />
        </div>
        <div class="right" style="flex-shrink: 0; display: flex">
          <TableConf function_code="goodsList" @onRowList="handleRowList" />
          <ExportConf function_code="allGoodsList" />
        </div>
      </div>
      <div class="table" v-if="rowList.length">
        <div class="total">
          <div class="total-right">
            <div class="total-right-item">
              成本 <span>{{ goodsTotal.cost_price }}元</span>
            </div>
            <div class="total-right-item">
              金重 <span>{{ goodsTotal.goldweight }}</span>
            </div>
            <div class="total-right-item">
              主石重 <span>{{ goodsTotal.msct }}</span>
            </div>
            <div class="total-right-item">
              数量 <span>{{ goodsTotal.pcs }}件</span>
            </div>
            <div class="total-right-item">
              标签价 <span>{{ goodsTotal.sale_price }}元</span>
            </div>
            <div class="total-right-item">
              总重 <span>{{ goodsTotal.totalweight }}</span>
            </div>
          </div>
        </div>
        <u-table
          :data="tableData"
          stripe
          :border="false"
          v-loading="loading"
          height="700"
          @row-dblclick="handleDalclick"
        >
          <u-table-column type="expand">
            <template
              slot-scope="scope"
              v-if="scope.row.goods_number_type === 20"
            >
              <div style="display: flex">
                <div
                  class="table-expand"
                  v-for="(item, index) in scope.row.mutile_goods"
                  :key="index"
                >
                  <div>
                    {{ item.status_name }}
                    <span style="margin-left: 5px">{{ item.goods_pcs }}</span>
                  </div>
                </div>
              </div>
            </template>
          </u-table-column>
          <template v-for="(item, index) in rowList">
            <u-table-column
              v-if="item.filed_status"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="String(item.field_text)"
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
            </u-table-column>
            <u-table-column
              v-else-if="item.field_alias === 'goods_name'"
              :key="index"
              :prop="item.field_alias"
              :label="String(item.field_text)"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div style="display: inline-block">
                  {{ scope.row[item.field_alias] || "--" }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img
                      :src="item"
                      :key="item"
                      style="margin-left: 5px; vertical-align: middle"
                    />
                  </template>
                </div>
              </template>
            </u-table-column>
            <u-table-column
              v-else
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="String(item.field_text)"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img src="../../../../../assets/images/no_img.png" />
                  </div>
                </div>
                <div v-else-if="item.field_alias === 'warning_state'">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-if="
                      scope.row[item.field_alias] !== 10 &&
                      scope.row[item.field_alias] !== 20 &&
                      scope.row[item.field_alias] !== 30
                    "
                  >
                    <div
                      style="
                        background-color: #21bc36;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 10"
                  >
                    <div
                      style="
                        background-color: #ffdf04;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 20"
                  >
                    <div
                      style="
                        background-color: #ff9000;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 30"
                  >
                    <div
                      style="
                        background-color: #f4390d;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                </div>
                <div v-else>
                  {{ scope.row[item.field_alias] || "--" }}
                </div>
              </template>
            </u-table-column>
          </template>
          <u-table-column
            align="center"
            fixed="right"
            prop="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleDetails($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <!-- <el-option label="上传图片" :value="2"></el-option> -->
                </el-select>
              </div>
            </template>
          </u-table-column>
        </u-table>
      </div>
      <div class="pagina" v-if="rowList.length">
        <el-pagination
          :current-page="cond.page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="goodImgVisible"
      title="导入货品图片"
      :destroy-on-close="true"
      width="25%"
      :before-close="cancelGoodImg"
    >
      <div>
        <el-form :model="goodImgForm">
          <div class="form">
            <el-form-item label="货品图片" label-width="90px">
              <el-upload
                class="avatar-uploader"
                :action="baseUrl + '/store/goods.Goods/uploadGoodsPic'"
                :data="{
                  goods_id: goods_id,
                  import_pic_type: 1,
                }"
                :headers="{
                  authorization: token,
                }"
                :show-file-list="false"
                :on-success="handleImgSuccess"
              >
                <img v-if="good_img" :src="good_img" class="avatarOther" />
                <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                ></i> </el-upload
            ></el-form-item>
          </div>
          <div style="text-align: right">
            <el-button type="primary" @click="cancelGoodImg">确定</el-button>
            <el-button @click="cancelGoodImg">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="goodAllImgVisible"
      title="批量导入货品图"
      :destroy-on-close="true"
      width="30%"
      :before-close="cancelGoodAllImg"
    >
      <div>
        <el-form :model="goodAllImgForm">
          <div class="form">
            <el-form-item label="导入类型" label-width="110px">
              <el-radio-group v-model="import_pic_type">
                <el-radio :label="1">按货号导入</el-radio>
                <el-radio :label="2">按款号导入</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="上传图片压缩包"
              v-if="import_pic_type"
              label-width="110px"
            >
              <el-upload
                :action="baseUrl + 'store/goods.Goods/uploadGoodsPicByZip'"
                :data="{
                  import_pic_type: import_pic_type,
                }"
                :headers="{
                  authorization: token,
                }"
                :show-file-list="false"
                :on-success="handleAllImgSuccess"
              >
                <el-button type="primary"
                  >上传<i
                    class="el-icon-upload el-icon--right"
                  ></i></el-button></el-upload
            ></el-form-item>
          </div>
          <div style="text-align: right">
            <el-button type="primary" @click="cancelGoodAllImg">确定</el-button>
            <el-button @click="cancelGoodAllImg">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <Details v-if="visiable" :goods_id="goods_id" @hide="handleCloseDetail()" />
  </div>
</template>

<script>
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import ExportConf from "@/components/exportConf/Index.vue";
import {
  getAllGoodsList,
  getDetailTitle,
  getGoodsDetails,
  getGoodsCount,
} from "@/api/goods/goodsStock/goodsDetailedlist/index";
import Details from "../details/Index";
import Cookies from "js-cookie";
import storage from "good-storage";
import { Message } from "element-ui";
export default {
  data() {
    return {
      exportLoading: false,
      tableData: [],
      activeName: "first",
      rowList: [],
      visiable: false,
      cond: {
        page: 1,
        page_num: 50,
      },
      loading: false,
      goodsInfo: [],
      total: 0,
      sale_price: "", // 标签价
      goods_name: "", // 品名
      goods_pic: null, // 图片
      formLabelWidth: "130px",
      goodAllImgVisible: false,
      goodAllImgForm: {},
      import_pic_type: null,
      otherList: [
        {
          prop: "",
          text: "期初库存",
          value: "",
        },
        {
          prop: "",
          text: "入库",
          value: "",
        },
        {
          prop: "",
          text: "调出",
          value: "",
        },
        {
          prop: "",
          text: "调入",
          value: "",
        },
        {
          prop: "",
          text: "销售",
          value: "",
        },
        {
          prop: "",
          text: "退货",
          value: "",
        },
        {
          prop: "",
          text: "退库",
          value: "",
        },
        {
          prop: "",
          text: "拆改",
          value: "",
        },
        {
          prop: "",
          text: "合计",
          value: "",
        },
      ],
      logList: [],
      rowList2: [
        {
          label: "操作时间",
          prop: "date",
        },
        {
          label: "操作记录",
          prop: "msg",
        },
        {
          label: "操作人",
          prop: "store_user_name",
        },
      ],
      goodImgForm: {},
      goodsTotal: {},
      good_img: "",
      goodImgVisible: false,
      goods_id: "",
      token: "",
      baseUrl: "",
    };
  },
  components: {
    Cond,
    TableConf,
    Details,
    ExportConf,
  },
  created() {
    if (this.$route.params.type && this.$route.params.type === "salesOrder") {
      this.visiable = true;
      this.goods_id = this.$route.params.goods_id;
    }
    this.token = storage.session.get("token") || Cookies.get("token");
    this.getList();
    this.getGoodsCount();
    const env = process.env.NODE_ENV;
    const host = window.location.host;
    if (env === "development") {
      this.baseUrl = "https://api.test.etouch.vip/";
    } else {
      if (host === "edianbao.test.etouch.vip") {
        this.baseUrl = "https://api.test.etouch.vip/";
      }
      if (host === "www.etouch.top") {
        this.baseUrl = "https://api.etouch.top/";
      }
      if (host === "edianbao.pretest.etouch.vip") {
        this.baseUrl = "https://api.pretest.etouch.vip/";
      }
    }
  },
  methods: {
    /*
     *  获取货品清单数据
     */
    getList() {
      this.loading = true;
      getAllGoodsList({ ...this.cond }).then((res) => {
        if (res.code === 1) {
          this.loading = false;
          res.data.list.map((item) => {
            for (var key in item) {
              if (key !== "goods_pic") {
                if (!item[key]) {
                  item[key] = "--";
                }
              }
            }
            return item;
          });
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  关闭详情
     */
    handleCloseDetail() {
      this.getList();
      this.visiable = false;
    },
    getGoodsCount() {
      getGoodsCount({ ...this.cond, isAll: 1 }).then((res) => {
        if (res.code === 1) {
          this.goodsTotal = res.data;
        }
      });
    },
    /*
     *  关闭详情弹窗
     */
    cancelDia() {
      this.activeName = null;
      this.visiable = false;
      this.getList();
      this.getGoodsCount();
    },
    handleCond(cond) {
      this.cond = {
        ...cond,
        page: 1,
        page_num: this.cond.page_num,
      };
      this.getList();
      this.getGoodsCount();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    /*
     *  分页操作
     */
    handleCurrentChange(val) {
      this.cond.page = val;
      this.loading = true;
      const data = {
        page: val,
        page_num: 15,
        ...this.cond,
      };

      getAllGoodsList(data).then((res) => {
        if (res.code === 1) {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
      this.getGoodsCount();
    },
    /*
     *  每页条数
     */
    handleSizeChange(val) {
      this.loading = true;
      this.cond.page_num = val;
      const data = {
        page: this.cond.page,
        page_num: this.cond.page_num,
        ...this.cond,
      };
      getAllGoodsList(data).then((res) => {
        if (res.code === 1) {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
      this.getGoodsCount();
    },
    handleGoodsAll() {
      this.goodAllImgVisible = true;
    },
    /*
     *  导出
     */
    handleExport() {
      this.exportLoading = true;
      const data = {
        export: 1,
        function_code: "allGoodsList",
        ...this.cond,
      };
      getAllGoodsList(data)
        .then((res) => {
          this.exportLoading = false;
          var filename = "货品清单.csv";
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = filename;
            a.href = url;
            a.click();
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    /*
     *  跳转到入库单
     */
    toWareHousing(val) {
      this.$router.push({
        // path: "/goods/godownEntry/godownEntryList",
        // query: { entry_number: val },
         name:'godownEntryList',
         params:{entry_number: val},
      });
    },
    handleDalclick(row) {
      this.goods_id = row.goods_id;
      this.visiable = true;
      // getDetailTitle({ goods_id: row.goods_id }).then((res) => {
      //   if (res.code === 1) {
      //   }
      // });
      // getGoodsDetails({
      //   goods_id: row.goods_id,
      // }).then((res) => {
      //   if (res.code === 1) {
      //     this.goodsInfo = res.data.baseInfo;
      //     this.sale_price = res.data.baseInfo.find(
      //       (item) => item.title === "标签价"
      //     ).val;
      //     this.goods_name = res.data.baseInfo.find(
      //       (item) => item.title === "品名"
      //     ).val;
      //     this.goods_pic = res.data.goods_pic;
      //   }
      // });
      // getGoodsLog({ goods_number: row.goods_number }).then((res) => {
      //   if (res.code === 1) {
      //     this.logList = res.data.list;
      //   }
      // });
    },
    /*
     *  操作
     */
    handleDetails(event, row) {
      this.goods_id = row.goods_id;
      if (event === 1) {
        this.activeName = "first";
        this.visiable = true;
        // getDetailTitle({ goods_id: row.goods_id }).then((res) => {
        //   if (res.code === 1) {
        //   }
        // });
        // getGoodsDetails({
        //   goods_id: row.goods_id,
        // }).then((res) => {
        //   if (res.code === 1) {
        //     this.goodsInfo = res.data.baseInfo;
        //     this.sale_price = res.data.baseInfo.find(
        //       (item) => item.title === "标签价"
        //     ).val;
        //     this.goods_name = res.data.baseInfo.find(
        //       (item) => item.title === "品名"
        //     ).val;
        //     this.goods_pic = res.data.goods_pic;
        //   }
        // });
        // getGoodsLog({ goods_number: row.goods_number }).then((res) => {
        //   if (res.code === 1) {
        //     this.logList = res.data.list;
        //   }
        // });
      } else if (event === 2) {
        this.goodImgVisible = true;
      }
    },
    handleTab() {},
    /*
     *  保存货品图片
     */
    sumbitGoodImg() {
      const data = {
        file: this.good_url,
      };
      uploadGoodsPic(data).then((res) => {
        if (res.code === 1) {
        }
      });
    },
    /*
     *  关闭上传图片弹窗
     */
    cancelGoodImg() {
      this.goodImgVisible = false;
      this.good_img = "";
      this.getList();
      this.getGoodsCount();
    },
    cancelGoodAllImg() {
      this.goodAllImgVisible = false;
      this.getList();
      this.getGoodsCount();
    },
    handleAllImgSuccess(res, file) {
      if (res.code === 1) {
        this.$message({
          type: "success",
          message: "上传成功！",
        });
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    handleImgSuccess(res, file) {
      if (res.code === 1) {
        this.good_img = res.data[0].path;
      } else {
        // 提示错误
        Message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  flex: 1;
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border: 1px solid #ddd;
  border-top: none;
  border-left: none;
  border-right: none;

  // border-radius: 0px 0px 6px 6px;
  &-left {
    // flex-shrink: 0;
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 25%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #e7541e;
      }
    }
  }
}
/deep/ .table-expand {
  width: 11.13%;
  color: #2791ff;
  text-align: center;
  border-right: 1px solid #ddd;
}

.goodsTitle {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .name {
    width: 100px;
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
  }
  .price {
    font-size: 16px;
    text-align: right;
    width: 260px;
  }
}

/deep/ .goodsInfo {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    width: 50%;
    line-height: 38px;

    .left {
      color: #909399;
      width: 30%;
      text-align: left;
      font-size: 14px;
    }
    .right {
      width: 70%;
      font-size: 14px;
    }
  }
}
/deep/ .el-tabs__item {
  font-size: 16px !important;
  line-height: 20px;
}
/deep/ .el-dialog__body {
  padding: 30px !important;
}
.image-slot {
  width: 50px;
  height: 50px;
  margin: 5px auto 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}
/deep/ .avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
/deep/ .avatar {
  width: 290px;
  height: 170px;
  display: block;
}
/deep/ .avatarOther {
  width: 100px;
  height: 100px;
  display: block;
}

/deep/ .auditSuccess {
  padding-bottom: 20px;
  margin-top: 10px;
  height: 450px;
  background-color: white;

  /deep/ .auditImg {
    margin: 10px auto 0 45%;
    width: 169px;
    height: 142px;
  }

  /deep/ .audit_text {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;

    span {
      font-size: 22px;
      color: #5493f5;
    }
  }
}
</style>    