<template>
  <div style="display: flex; margin-left: 10px;">
    <!-- <div class="cutline"></div> -->
    <el-button
      @click="getTableConf(2)"
      type="primary"
      plain
      style="margin-left: auto; height: 40px"
    >
      <i class="iconfont iconliebiao1"></i>导出配置
    </el-button>
    <el-dialog
      :visible.sync="tableVisiable"
      title="导出配置"
      width="30%"
      :destroy-on-close="true"
      :before-close="cancelTable"
    >
      <el-button
        type="primary"
        plain
        style="margin-bottom: 20px"
        @click="handleReset"
        >恢复默认</el-button
      >
      <!-- <span style="color: #F56C6C; margin-left: 10px;">*可拖拽行改变列表排序</span> -->
      <div class="table" style="border: 1px solid #ddd">
        <!-- <el-table
          :data="rowList"
          stripe
          height="400"
          @cell-click="hanldeCellClick"
          :cell-class-name="getCellIndex"
        >
          <el-table-column align="center" prop="index" label="排序">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-top"
                v-if="scope.row.index !== 0"
                @click="handleArrSort(scope.row.index, scope.row.index - 1)"
              ></el-button>
              <el-button
                size="mini"
                icon="el-icon-bottom"
                v-if="scope.row.index + 1 !== rowList.length"
                @click="handleArrSort(scope.row.index, scope.row.index + 1)"
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="field_text"
            label="导出字段"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="t_width"
            label="宽度(px)"
            column-key="3"
          >
            <template slot-scope="scope">
              <el-input
                @blur="handleBlur"
                :id="'input' + scope.row.index + scope.column.index"
                v-model="scope.row.t_width"
                v-enter-number
                v-show="
                  coordinate.column === scope.column.index &&
                  coordinate.row === scope.row.index
                "
              >
              </el-input>
              <span
                v-show="
                  scope.row.index != coordinate.row ||
                  scope.column.index != coordinate.column
                "
              >
                {{ scope.row.t_width }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="show" label="是否导出">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.t_is_use"
                :active-value="1"
                :disabled="
                  scope.row.field_alias === 'entry_number' ? true : false
                "
                :inactive-value="0"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table> -->
        <div style="height: 400px; overflow-y: auto;">
        <ux-grid
                border
                row-key
                ref="plxTable"
                :data="rowList">
            <ux-table-column
                    v-for="item in tableColumn"
                    align="center"
                    :key="item.id"
                    :resizable="item.resizable"
                    :field="item.field"
                    :title="item.title"
                    :sortable="item.sortable"
                    :width="item.minWidth"
                    :fixed="item.fixed">
                    <template v-slot="scope" v-if="item.field === 't_width'">
                      <el-input v-model="scope.row.t_width"></el-input>
                    </template>
            </ux-table-column>
            <ux-table-column title="是否导出" align="center" field="t_is_use">
              <template  v-slot="scope">
                <el-switch
                v-model="scope.row.t_is_use"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
              </template>
            </ux-table-column>
        </ux-grid>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tableVisiable = false">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import { tableConfReq, tableConfSet, restoreReq } from "./index";

export default {
  name: "TableConf",
  data() {
    return {
      tableData: [],
      tableColumn: [
        { title: '导出字段', field: 'field_text' },
        // { title: '宽度(px)', field: 't_width' },
        // { title: '是否导出', field: 't_is_use' },
      ],
      loading: false,
      coordinate: {},
      tableVisiable: false,
      rowList: [],
      usedList: [],
      noUsedList: [],
    };
  },
  watch: {
    tableVisiable: {
      handler(val) {
        if (val) {
          this.columnDrop();
          this.columnDrop2();
          this.columnDrop3();
          this.columnDrop4();
        }
      },
      immediate: true,
    },
  },
  props: {
    function_code: String,
    tab: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  created() {
    this.getTableConf(1);
  },
  mounted() {
   
  },
  // beforeDestroy () {
  //     if (this.sortable) {
  //         this.sortable.destroy()
  //     }
  // },
  methods: {
    columnDrop () {
      this.$nextTick(() => {
          let plxTable = this.$refs.plxTable;
          if (!document.getElementsByClassName('elx-table--body')[0]) {
            return;
          }
          const tbodyDom = document.getElementsByClassName('elx-table--body')[0].getElementsByTagName("tbody")[0];
          // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
          this.sortable = Sortable.create(tbodyDom, {
              handle: ".elx-body--row",  // Drag handle selector within list items
              // 拖拽结束
              onEnd: ({ item, newIndex, oldIndex }) => {
                  let currRow = this.rowList.splice(oldIndex, 1)[0]
                  this.rowList.splice(newIndex, 0, currRow)
              }
          })
      })
    },
    columnDrop2 () {
      this.$nextTick(() => {
          let plxTable = this.$refs.plxTable
          if (!document.getElementsByClassName('elx-table--body')[1]) {
            return;
          }
          const tbodyDom = document.getElementsByClassName('elx-table--body')[1].getElementsByTagName("tbody")[0];
          // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
          this.sortable = Sortable.create(tbodyDom, {
              handle: ".elx-body--row",  // Drag handle selector within list items
              // 拖拽结束
              onEnd: ({ item, newIndex, oldIndex }) => {
                  let currRow = this.rowList.splice(oldIndex, 1)[0]
                  this.rowList.splice(newIndex, 0, currRow)
              }
          })
      })
    },
    columnDrop3 () {
      this.$nextTick(() => {
          let plxTable = this.$refs.plxTable
          if (!document.getElementsByClassName('elx-table--body')[2]) {
            return;
          }
          const tbodyDom = document.getElementsByClassName('elx-table--body')[2].getElementsByTagName("tbody")[0];
          // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
          this.sortable = Sortable.create(tbodyDom, {
              handle: ".elx-body--row",  // Drag handle selector within list items
              // 拖拽结束
              onEnd: ({ item, newIndex, oldIndex }) => {
                  let currRow = this.rowList.splice(oldIndex, 1)[0]
                  this.rowList.splice(newIndex, 0, currRow)
              }
          })
      })
    },
    columnDrop4 () {
      this.$nextTick(() => {
          let plxTable = this.$refs.plxTable
          if (!document.getElementsByClassName('elx-table--body')[3]) {
            return;
          }
          const tbodyDom = document.getElementsByClassName('elx-table--body')[3].getElementsByTagName("tbody")[0];
          // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
          this.sortable = Sortable.create(tbodyDom, {
              handle: ".elx-body--row",  // Drag handle selector within list items
              // 拖拽结束
              onEnd: ({ item, newIndex, oldIndex }) => {
                  let currRow = this.rowList.splice(oldIndex, 1)[0]
                  this.rowList.splice(newIndex, 0, currRow)
              }
          })
      })
    },
    handleReset() {
      this.$confirm("确定要重置列表配置吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        restoreReq(this.function_code)
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableVisiable = false;
              this.getTableConf(1);
              this.$message.success("重置成功");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    handleBlur() {
      this.coordinate = {
        column: -1,
        row: -1,
      };
    },
    // 数组交换位置
    handleArrSort(sort1, sort2) {
      this.rowList.splice(
        sort1,
        1,
        ...this.rowList.splice(sort2, 1, this.rowList[sort1])
      );
      this.rowList.forEach((item, index) => {
        item.t_order = this.rowList.length - index;
      });
    },
    handleInput(e) {
      const a = e.key.replace(/[^\d]/g, "");
      if (!a && e.keyCode !== 8) {
        e.preventDefault();
      }
    },
    handleTrue() {
      this.loading = true;
      // 需要重新渲染表格
      this.$emit("onRowList", []);
      this.rowList.forEach((item, index) => {
        item.t_order = this.rowList.length - index;
      });
      tableConfSet({
        function_code: this.function_code,
        data: this.rowList,
        tab: this.tab,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableVisiable = false;
            this.getTableConf(1);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
    },
    hanldeCellClick(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row.index;
      coordinate.column = column.index;
      this.coordinate = coordinate;
      const dom = document.getElementById("input" + row.index + column.index);
      this.$nextTick(() => {
        try {
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    },
    getCellIndex({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },
    // 获取列表配置项
    getTableConf(type) {
      tableConfReq(this.function_code, this.tab).then((res) => {
        if (res.code === 1) {
          // 将列表配置分割为使用和未使用的
          const { data } = res;
          if (type === 1) {
            this.usedList = data.filter((item) => item.t_is_use);
            this.noUsedList = data.filter((item) => !item.t_is_use);
            this.$emit("onRowList", this.usedList);
            this.rowList = JSON.parse(JSON.stringify(data));
          } else {
            this.tableVisiable = true;
            this.rowList = JSON.parse(JSON.stringify(data));
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.cutline {
  margin: 0 20px;

  width: 1px;
  height: 40px;
  background: #e9e9e9;
}
</style>
