import storage from 'good-storage';
const mixin = {
  methods: {
    handleGoods(event) {
      if (event.target.nodeName === 'A') {
        const key = event.target.getAttribute('data-key');
        var name = event.target.getAttribute('data-name');
        const goodsType = event.target.getAttribute('data-type');
        const numberValue = event.target.getAttribute('data-value');
        const name_keys = storage.get('name_keys');
        if (name === 'WareHousingList') {
          // 权限里是否有销售订单
          name =  'godownEntryList'
        }
        // 当路由跳转是订单时，需要做判断，因为每个订单都有两个；
        if (name === 'offlineOrderSalesOrder') {
          // 权限里是否有销售订单
          if (name_keys['/order/offlineOrder/salesOrder']) {
            this.$router.push({
              name: 'offlineOrderSalesOrder',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          } else if (name_keys['/order/offlineOrder/mySalesOrder']) {
            this.$router.push({
              name: 'offlineOrderMySalesOrder',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          } else {
            this.$router.push({
              name,
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          }
          return;
        }
        if (name === 'offlineOrderReturnOrder') {
          // 权限里是否有退货订单
          if (name_keys['/order/offlineOrder/returnOrder']) {
            this.$router.push({
              name: 'offlineOrderReturnOrder',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          } else if (name_keys['/order/offlineOrder/myReturnOrder']) {
            this.$router.push({
              name: 'offlineOrderMyReturnOrder',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          } else {
            this.$router.push({
              name,
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          }
          return;
        }
        if (name === 'changeOrder') {
          // 权限里是否有回收订单
          if (name_keys['/order/offlineOrder/exchangeOrder']) {
            this.$router.push({
              name: 'changeOrder',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          } else if (name_keys['/order/offlineOrder/myExchangeOrder']) {
            this.$router.push({
              name: 'myChangeOrder',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          } else {
            this.$router.push({
              name,
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          }
          return;
        }
        if (name === 'redeemOrder') {
          // 权限里是否有赎回订单
          if (name_keys['/order/offlineOrder/redeemOrder']) {
            this.$router.push({
              name: 'redeemOrder',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          } else if (name_keys['/order/offlineOrder/myRedeemOrder']) {
            this.$router.push({
              name: 'myRedeemOrder',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          } else {
            this.$router.push({
              name,
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key,
              },
            });
          }
          return;
        }
        this.$router.push({
          name,
          query: {
            t: Date.now(),
          },
          params: {
            type: 'detail',
            key,
            goodsType,
          },
        });
      }
    },
  },
};

export {
  mixin,
};
